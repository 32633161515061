<template>
  <div>
    <b-row class="match-height">
      <b-col
        cols="12"
        xl="12"
      >
        <b-card no-body class="user-top">
          <b-card-body>
            <div class="user-text">
              <feather-icon
                class="user-icon"
                icon="UserIcon"
              />
              <span class="user-ativo">
                Utilizadores ativos
              </span>
            </div>
          </b-card-body>
        </b-card>
        <b-card no-body>
          <b-card-body class="offset-1 col-10">
            <b-table
              :items="items"
              :fields="fields"
              striped
              responsive
              class="user-list mb-0"
            >
              <template #cell(avatar)="data">
                <b-avatar :src="data.value" />
              </template>
              <template #cell(cargo)="data">
                <v-select
                  class="status-select"
                  placeholder="Cargo"
                  :options="data.value"
                />
              </template>
              <template #cell(approval)="data">
                <div class="icons-list">
                  <feather-icon
                    class="approval-icon"
                    :icon="data.value"
                  />
                </div>
              </template>
              <template #cell(show_details)="row">
                <div class="checkbox-list">
                  <b-form-checkbox
                    v-model="row.detailsShowing"
                    @change="row.toggleDetails"
                  ></b-form-checkbox>
                </div>
              </template>
            </b-table>
          </b-card-body>
        </b-card>
      </b-col>
      <template #code>
        {{ codeRowDetailsSupport }}
      </template>
    </b-row>
  </div>
</template>

<script>
import {
  BCard, BCardText, BButton, BRow, BCol, BCardBody,
  BFormGroup, BFormInput, BInputGroup, BInputGroupPrepend,
  BTable, BFormCheckbox, BAvatar, BBadge,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import vSelect from 'vue-select'
import { codeRowDetailsSupport } from './code'

export default {
  components: {
    BCard,
    BCardText,
    BButton,
    BCardBody,
    BRow,
    BCol,
    BFormGroup, 
    BFormInput, 
    BInputGroup, 
    BInputGroupPrepend,
    BTable, 
    BFormCheckbox, 
    BAvatar, 
    BBadge,
    vSelect,
  },
  directives: {
    Ripple,
  },
  data() {
    return {
      fields: [{ key: 'avatar', label: '' }, {key: 'full_name', label: 'NOME'}, {key: 'email', label: 'EMAIL'}, { key: 'cargo', label: 'CARGO' }, { key: 'show_details', label: '' }],
      /* eslint-disable global-require */
      items: [
        {
          id: 1,
          avatar: require('@/assets/images/avatars/10-small.png'),
          full_name: "Korrie O'Crevy",
          email: 'kocrevy0@thetimes.co.uk',
          cargo: ['Administrador', 'User', 'Técnico'],
        },
        {
          id: 2,
          avatar: require('@/assets/images/avatars/1-small.png'),
          full_name: 'Bailie Coulman',
          email: 'bcoulman1@yolasite.com',
          cargo: ['Administrador', 'User', 'Técnico'],
        },
        {
          id: 3,
          avatar: require('@/assets/images/avatars/9-small.png'),
          full_name: 'Stella Ganderton',
          email: 'sganderton2@tuttocitta.it',
          cargo: ['Administrador', 'User', 'Técnico'],
        },
      ],
      codeRowDetailsSupport,
    }
  },
}
</script>